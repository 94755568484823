import { createSlice } from "@reduxjs/toolkit";
import { API_ADD_RESTATURANT_MENU, API_CUISINE_LIST, API_DELETE_ADVERTISE, API_FOOD_ITEMS_LIST, API_GET_ALL_CHAIN_RESTAURANT_LIST, API_GET_CMS_DETAIL, API_GET_LIST_RESTAURANT_FOR_VENUE, API_GET_RESTATURANT_MENU, API_GET_RESTAURANT_DETAILS_FOR_VENUE, API_GOAT_DELETE_HISTORY, API_GOAT_HISTORY, API_LIST_CHAINED_RESTAURANTS, API_LIST_SINGLE_RESTAURANTS, API_MEAL_LIST, API_NEW_PASSWORD, API_RESET_PASSOWORD_USER, API_RESTAURANT_REQUEST, API_UPDATE_RESTATURANT_MENU, API_VENUE_ADD_RESTAURANT, API_VENUE_DELETE_RESTAURANT, API_VENUE_LIST_RESTAURANT, API_VENUE_NAV_BAR, API_VENUE_UNCLAIM, API_VENUE_UPDATE_RESTAURANT, API_VERIFY_VENUE, CUSINE_SUCCESS, FOOD_ITEM_SUCESS, GET_RESTAURANT_MENU_SUCESS, MEAL_SUCCESS, VENUE_ADD_RESTAURANT_SUCCESS, VENUE_LIST_RESTAURANT_SUCCESS } from "../constants";

const initialState = {
  restaurantList: [],
  recordsTotal: 0,
  cmsDetails: {},
  goatList: { data: [], recordsTotal: 0 },
  restaurantVenue: { data: [], recordsTotal: 0 },
  restaurantVenueDetails: {},
  adsStatusDetails: {}
};

// export const AddRestaurant = (data) => ({
//   type: "API",
//   payload: {
//     url: API_VENUE_ADD_RESTAURANT,
//     method: "POST",
//     data: data,
//     hideLoader: false,
//     success: (data) => ({
//       type: VENUE_ADD_RESTAURANT_SUCCESS,
//       payload: data,
//     })
//   },
// });

export const updateRestaurant = (data) => ({
  type: "API",
  payload: {
    url: API_VENUE_ADD_RESTAURANT,
    method: "PATCH",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: VENUE_ADD_RESTAURANT_SUCCESS,
      payload: data,
    })
  },
});

export const getGoatList = (data) => ({
  type: 'API',
  payload: {
    url: API_GOAT_HISTORY,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getGoatList/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getGoatList/fail',
      payload: {},
    }),
  },
})

export const goatDelete = (data) => ({
  type: "API",
  payload: {
    url: API_GOAT_DELETE_HISTORY,
    method: "DELETE",
    data: data
  },
});
export const LISTRestaurant = (data) => ({
  type: "API",
  payload: {
    url: API_VENUE_LIST_RESTAURANT,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: VENUE_LIST_RESTAURANT_SUCCESS,
      payload: data,
    })
  },
});
export const RESTAURANT_REQUEST = (data) => ({
  type: "API",
  payload: {
    url: API_RESTAURANT_REQUEST,
    method: "POST",
    data: data,
    hideLoader: false
  }
});
export const SET_NEW_PASSWORD = (data) => ({
  type: "API",
  payload: {
    url: API_NEW_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false
  }
});
export const SET_USER_NEW_PASSWORD = (data) => ({
  type: "API",
  payload: {
    url: API_RESET_PASSOWORD_USER,
    method: "POST",
    data: data,
    hideLoader: false
  }
});
export const UPDATERestaurant = (data) => ({
  type: "API",
  payload: {
    url: API_VENUE_UPDATE_RESTAURANT,
    method: "PATCH",
    data: data
  },
});

export const DELETERestaurant = (data) => ({
  type: "API",
  payload: {
    url: API_VENUE_DELETE_RESTAURANT,
    method: "DELETE",
    data: data
  },
});

export const UnclaimVenue = (data) => ({
  type: 'API',
  payload: {
    url: API_VENUE_UNCLAIM,
    method: 'POST',
    data: data
  }
})

export const deleteAdvertise = (data) => ({
  type: "API",
  payload: {
    url: API_DELETE_ADVERTISE,
    method: "PATCH",
    data: data
  },
});

// Common list of cuisine , meal, items..
export const CUISINE_LIST = (data) => ({
  type: "API",
  payload: {
    url: API_CUISINE_LIST,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: CUSINE_SUCCESS,
      payload: data,
    })
  },
});
export const MEAL_LIST = (data) => ({
  type: "API",
  payload: {
    url: API_MEAL_LIST,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: MEAL_SUCCESS,
      payload: data,
    })
  },
});
export const FOOD_ITEM_LIST = (data) => ({
  type: "API",
  payload: {
    url: API_FOOD_ITEMS_LIST,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: FOOD_ITEM_SUCESS,
      payload: data,
    })
  },
});
export const ADD_RESTAURANT_MENU = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_RESTATURANT_MENU,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: FOOD_ITEM_SUCESS,
      payload: data,
    })
  },
});


export const GET_RESTATURANT_MENU = (data) => ({
  type: "API",
  payload: {
    url: API_GET_RESTATURANT_MENU,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: GET_RESTAURANT_MENU_SUCESS,
      payload: data,
    })
  },
});

export const UPDATE_RESTAURANT_MENU = (data) => ({
  type: "API",
  payload: {
    url: API_UPDATE_RESTATURANT_MENU,
    method: "POST",
    data: data,
    hideLoader: false,
  },
})

export const cmsGetAction = (data) => ({
  type: "API",
  payload: {
    url: API_GET_CMS_DETAIL(data),
    method: "GET",
    // data: data,
    hideLoader: false,
    success: (data) => ({
      type: "cms/success",
      payload: data,
    }),
    error: (data) => ({
      type: "cms/fail",
      payload: {},
    }),
  },
});

//getrestaurant list for venue
export const getRestaurantListforVenue = (data) => ({
  type: 'API',
  payload: {
    url: API_GET_LIST_RESTAURANT_FOR_VENUE,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getRestaurantListVenue/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getRestaurantListVenue/fail',
      payload: {},
    }),
  },
})


export const GetRestaurantDetailforVenue = (data) => ({
  type: 'API',
  payload: {
    url: API_GET_RESTAURANT_DETAILS_FOR_VENUE,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'resDetail/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'resDetail/fail',
      payload: {},
    }),
  },
})

export const verifyRestaurant = (data) => ({
  type: 'API',
  payload: {
    url: API_VERIFY_VENUE,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'verifyRestaurant/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'verifyRestaurant/fail',
      payload: {},
    }),
  },
})

export const verifyVenueNavbar = (data) => ({
  type: 'API',
  payload: {
    url: API_VENUE_NAV_BAR,
    method: 'GET',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'verifyVenueNavbar/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'verifyVenueNavbar/fail',
      payload: {},
    }),
  },
})

export const getAllChianList = (data) => ({
  type: 'API',
  payload: {
    url: API_GET_ALL_CHAIN_RESTAURANT_LIST,
    method: 'GET',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getAllChainList/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getAllChainList/fail',
      payload: {},
    }),
  },
})

export const getAllChainedList = (data) => ({
  type: 'API',
  payload: {
    url: API_LIST_CHAINED_RESTAURANTS,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getAllChainedList/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getAllChainedList/fail',
      payload: {},
    }),
  },
})
export const getSingleRestaurantList = (data) => ({
  type: 'API',
  payload: {
    url: API_LIST_SINGLE_RESTAURANTS,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getSingleRestaurantList/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getSingleRestaurantList/fail',
      payload: {},
    }),
  },
})

// Reducer
const VenueSlice = createSlice({
  name: "VenueSlice",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(VENUE_LIST_RESTAURANT_SUCCESS, (state, action) => {
      state.restaurantList = action.payload.data.data;
      state.recordsTotal = action.payload.data.recordsTotal;
    });
    builder.addCase(CUSINE_SUCCESS, (state, action) => {
      state.cuisineList = action.payload.data.data;
    });
    builder.addCase(MEAL_SUCCESS, (state, action) => {
      state.mealList = action.payload.data.data;
    });
    builder.addCase(FOOD_ITEM_SUCESS, (state, action) => {
      state.fooditemList = action.payload.data.data;
    });
    builder.addCase(GET_RESTAURANT_MENU_SUCESS, (state, action) => {
      state.getRestaurantMenu = action.payload.data.data;
    });
    builder.addCase('cms/success', (state, action) => {
      state.cmsDetails = action.payload.data
    })
    builder.addCase('cms/fail', (state, action) => {
      state.cmsDetails = {}
      state.isLoggedIn = false
    })
    builder.addCase('getGoatList/success', (state, action) => {
      state.goatList = action.payload.data
    })
    builder.addCase('getGoatList/fail', (state, action) => {
      state.goatList = { data: [], recordsTotal: 0 }
      state.isLoggedIn = false
    })
    builder.addCase('getRestaurantListVenue/success', (state, action) => {
      state.restaurantVenue = action.payload.data
    })
    builder.addCase('getRestaurantListVenue/fail', (state, action) => {
      state.restaurantVenue = { data: [], recordsTotal: 0 }
      state.isLoggedIn = false
    })
    builder.addCase('resDetail/success', (state, action) => {
      state.restaurantVenueDetails = action.payload.data
    })
    builder.addCase('resDetail/fail', (state, action) => {
      state.restaurantVenueDetails = {}
      state.isLoggedIn = false
    })
    builder.addCase('verifyVenueNavbar/success', (state, action) => {
      state.adsStatusDetails = action.payload.data
    })
    builder.addCase('verifyVenueNavbar/fail', (state, action) => {
      state.adsStatusDetails = {}
      state.isLoggedIn = false
    })
    builder.addCase('getAllChainList/success', (state, action) => {
      state.getAllChainList = action.payload.data
    })
    builder.addCase('getAllChainList/fail', (state, action) => {
      state.getAllChainList = { data: [], recordsTotal: 0 }
      state.isLoggedIn = false
    })
    builder.addCase('getAllChainedList/success', (state, action) => {
      state.getAllChainedList = action.payload.data
    })
    builder.addCase('getAllChainedList/fail', (state, action) => {
      state.getAllChainedList = { data: [], recordsTotal: 0 }
      state.isLoggedIn = false
    })
    builder.addCase('getSingleRestaurantList/success', (state, action) => {
      state.getSingleRestaurantList = action.payload.data
    })
    builder.addCase('getSingleRestaurantList/fail', (state, action) => {
      state.getSingleRestaurantList = { data: [], recordsTotal: 0 }
      state.isLoggedIn = false
    })
  },
});

export const { loaderChange } = VenueSlice.actions;
export default VenueSlice.reducer;
